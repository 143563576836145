import request from '@/api/request'
//门店分布数据
export function showShopStatistics(params) {
	return request({ url: '/gateway/shop/showMap/showShopStatistics',method: 'get',data: params})
}

export function showShopSearch(params) {
	return request({ url: '/gateway/shop/showMap/showShopSearch',method: 'get',data: params})
}

export function showShop(params) {
	return request({ url: '/gateway/shop/showMap/showShop',method: 'get',data: params})
}


//店铺详情
 export function detail(params) {
	return request({ url: '/gateway/walker/work/shopDetail',method: 'get',data: params})
}

 export function getRegionId(params) {
	return request({ url: '/gateway/shop/showMap/getRegionId',method: 'get',data: params})
}

 export function regionAllListByParentRegionId(params) {
	return request({ url: '/gateway/shop/showMap/regionAllListByParentRegionId',method: 'get',data: params})
}

